import * as React from 'react';
import withWSMessageReceiver, {
  WSMessageReceiverComponent,
} from '../../../utils/withWSMessageReceiver';
import { RoomAdminComponentProps } from '../roomAdmin';
import Admin from '../admin';

const ConfidenceVoteAdmin: WSMessageReceiverComponent<RoomAdminComponentProps> = (
  { registrationData, sendWS, participants },
  ref,
) => {
  return (
    <Admin
      ref={ref}
      registrationData={registrationData}
      sendWS={sendWS}
      participants={participants}
    />
  );
};

ConfidenceVoteAdmin.displayName = 'ConfidenceVoteAdmin';

export default withWSMessageReceiver(ConfidenceVoteAdmin);
