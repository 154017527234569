import * as React from 'react';
import { VoidPageProps } from '../../../utils/graphql';
import ConfidenceVoteAdmin from '../../../components/tools/confidence-vote/confidenceVoteAdmin';
import Animate from '../../../components/tools/animate';

const ConfidenceVoteAnimate: React.VoidFunctionComponent<VoidPageProps> = ({ pageContext }) => {
  return (
    <Animate
      pageContext={pageContext}
      toolId="confidence-vote"
      RoomComponent={ConfidenceVoteAdmin}
    />
  );
};

export default ConfidenceVoteAnimate;
